import React from "react";
import "./Header.css";
import headerImg from "../images/driehaus-award.png";

function Header() {
  return (
    <div className="header" id="header">
      <img src={headerImg} alt="Header Graphic" className="headerImg" />
    </div>
  );
}

export default Header;
