import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import DropdownButton from "../Dropdown/DropdownButton";
import logo from "../images/logo-blue.png";
import "./Navbar.css";

const Navbar = (props) => {
  let navClass = "";
  if (props.sticky) {
    navClass = "sticky";
  }
  return (
    <div className={navClass}>
      <div className="nav-bar" id="navbar">
        <a className="brand-link" href="https://www.bettergov.org">
          <img src={logo} alt="" />
        </a>
        <div className="spacer" />
        <div className="navLinks">
          <ul>
            <li className="hide-on-small-only">
              <a href="https://www.bettergov.org/about-us">About Us</a>
            </li>
            <li className="hide-on-small-only">
              <a href="https://donate.bettergov.org">Donate</a>
            </li>
            <li>
              <DropdownButton
                click={props.dropdownHandler}
                animate={props.buttonAnimation}
              />
            </li>
          </ul>
        </div>
      </div>
      <Dropdown show={props.dropdownShow} />
    </div>
  );
};

export default Navbar;
