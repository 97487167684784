import React, { Component } from "react";
import Header from "./Components/Header/Header";
import Body from "./Components/Body/Body";
import EventDescription from "./Components/EventDescription/EventDescription";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {
      isSticky: false,
      isOpen: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const header = document.getElementById("header");
    const navbar = header.offsetHeight;

    if (window.pageYOffset > navbar) {
      this.setState({
        isSticky: true,
      });
    } else if (window.pageYOffset < navbar) {
      this.setState({
        isSticky: false,
      });
    }
  };

  toggleHandler = () => {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen };
    });
  };

  render() {
    return (
      <div>
        <Header />
        <Navbar
          sticky={this.state.isSticky}
          dropdownHandler={this.toggleHandler}
          dropdownShow={this.state.isOpen}
          buttonAnimation={this.state.isOpen}
        />
        <Body>
          <EventDescription />
        </Body>
        <Footer />
      </div>
    );
  }
}

export default App;
