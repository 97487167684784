import React from "react";
import DriehausLogo from "../images/driehaus-logo.png";
import logo from "../images/logo-blue.png";
import "./Footer.css";

const Footer = (props) => (
  <div className="footer-container">
    <div className="row center">
      <div className="col s12 m4 l4">
        <span>Sponsor</span>
        <br />
        <img className="driehausLogo" src={DriehausLogo} alt="" />
      </div>
      <div className="col s12 m4 l4">
        <p>Follow us on:</p>
        <div style={{display: "flex", justifyContent: "space-evenly"}}>
          <a href="https://www.facebook.com/bettergov/">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com/bettergov">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
      <div className="col s12 m4 l4">
        <img
          className="bga-logo"
          src={logo}
          alt=""
          style={{ margin: "30px 0" }}
        />
      </div>
    </div>

    <p
      style={{
        color: "#202124",
        padding: "20px",
        fontSize: "15px",
        textAlign: "center",
      }}
    >
      &copy; 2020 Better Government Association
    </p>
  </div>
);

export default Footer;
