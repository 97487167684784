import React from "react";

(function (d, s) {
  var js,
    el = d.getElementsByTagName(s)[0];
  window.BB_REG_FORM_ID = "ea30d107-a120-435c-8a28-0de56b328c65";
  window.BB_REG_FORM_ENVIRONMENT_ID = "p-fEp6e6mKFk2iQ5k7ZFAgFg";
  js = d.createElement(s);
  js.src =
    "https://sky.blackbaudcdn.net/skyuxapps/registration-form/assets/online_registrations_form_loader_v1.f8744b9a2e1826070a839886cbb55c9de563274d.js";
  el.parentNode.insertBefore(js, el);
})(document, "script");

function Iframe() {
  return <div id="blackbaud-registration-form"></div>;
}

export default Iframe;
