import React from "react";
import "./Dropdown.css";

const Dropdown = (props) => {
  let drawerClasses = "dropdown";
  if (props.show) {
    drawerClasses = "dropdown open";
  }
  return (
    <div className={drawerClasses}>
      <ul>
        <li>
          <a href="https://www.bettergov.org/about-us">About Us</a>
        </li>
        <li>
          <a href="https://donate.bettergov.org">Donate</a>
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
