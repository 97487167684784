import React, { Component } from "react";
import Iframe from "../Iframe/Iframe";
import "./EventDescription.css";

class Body extends Component {
  constructor() {
    super();
    this.state = {
      showing: false,
    };
  }

  render() {
    const { showing } = this.state;
    return (
      <>
        <div className="row" style={{ maxWidth: "768px" }}>
          <div className="col s12 m12 l12 column-1">
            <p className="event-description">
              The Better Government Association will present the 2020 Richard H.
              Driehaus Foundation Awards for Investigative Reporting in a
              virtual video ceremony at 4:30 p.m. Tuesday, July 14.
            </p>

            <strong style={{ fontWeight: "bolder" }}>
              The awards presentation will stream on
              <a
                className="formStack"
                href="https://www.youtube.com/watch?v=I9q_giX_shY&feature=youtu.be"
                style={{ color: "#fa2929" }}
              >
                {" "}
                YouTube here
              </a>
              .
            </strong>

            <p>
              The Driehaus Awards recognize and honor the talent and tenacity of
              investigative journalists whose work reveals governmental
              corruption, waste, inefficiency, mismanagement, mistreatment and
              injustice.
            </p>
            <p>
              The ceremony will showcase video highlights of investigative
              stories from five finalists. In conversation with Mr. Driehaus,
              the founding partner of the awards, BGA President David Greising
              will explore the vital role such investigations play in protecting
              and advancing democracy.
            </p>
            <p>
              Capping off the ceremony, we’ll announce three winners selected by
              a jury—and a special, additional Reader's Choice award you helped
              choose.
            </p>

            <p>
              Nearly two dozen compelling investigative stories were submitted
              to this year’s award competition. The first-place winner receives
              a $15,000 prize; second- and third-place winners receive $7,000
              and $4,000. (The inaugural People’s Choice award winner will
              receive $1,000.)
            </p>
            <p>
              In a climate where journalism is financially at risk, trust in the
              media is eroding and reporters are threatened, we encourage you to
              join us for this free event honoring and rewarding the
              extraordinary contributions of those who hold officials
              accountable.
            </p>
            <p>
              For more information on the event, please contact{" "}
              <a href="mailto:tshears@bettergov.org">tshears@bettergov.org</a>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col s12 m12 l12 iframe-trigger">
            <button
              type="button"
              className="btn trigger"
              onClick={() => this.setState({ showing: !showing })}
            >
              {showing ? "Hide" : "Register"}
            </button>
            <div style={{ display: showing ? "block" : "none" }}>
              <Iframe />
            </div>
            <br />
            <hr />
          </div>
        </div>
      </>
    );
  }
}

export default Body;
