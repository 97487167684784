import React from "react";
import "./DropdownButton.css";

const DropdownButton = (props) => {
    let btnClass = "btn-line";
    if (props.animate) {
        btnClass = "btn-line change"
    }
  return (
    <div className="btn-container hide-on-med-and-up" onClick={props.click}>
      <div className={`${btnClass} bar1`}></div>
      <div className={`${btnClass} bar2`}></div>
      <div className={`${btnClass} bar3`}></div>
    </div>
  );
};

export default DropdownButton;
